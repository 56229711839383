<template>
  <div :style="wrapper" id="main-style">
    <router-view/>
  </div>
</template>
<script>
import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse("OTtV4gIlkiAmfR43"); // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse("0"); // 十六位十六进制数作为密钥偏移量
function Decrypt(word) {
  try {
    let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
    let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    let decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
  } catch (res) {
    return ''
  }

}
function getHashParams() {
  var hashParams = {};
  var hash = getSubstringAfterQuestionMark(window.location.href);
  var pattern = /&cid=\d*/;
  var notAES = pattern.test("?"+hash);
  if(!notAES) {
    hash = Decrypt(hash);
  }
  if(hash !== ''){
    var params = hash.split('&');
    for (var i = 0; i < params.length; i++) {
      var param = params[i].split('=');
      var key = decodeURIComponent(param[0]);
      var value = decodeURIComponent(param[1]);
      hashParams[key] = value;
    }
    if(!notAES) {
      hashParams.token = hashParams.sid;
    }
    return hashParams;
  } else {
    return ''
  }

}
function getSubstringAfterQuestionMark(inputString) {
  const index = inputString.indexOf('?');
  if (index !== -1) {
    return inputString.substring(index + 1);
  } else {
    return '';
  }
}
</script>
<script setup>
import {onMounted, ref} from 'vue'
import { setCid, setToken, setUUid, setVer} from "@/utils/auth";
const ua = navigator.userAgent;
const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
    isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
    isAndroid = ua.match(/(Android)\s+([\d.]+)/),
    isMobile = isIphone || isAndroid;
const wrapper = ref();
if (!isMobile) {
  document.documentElement.style.fontSize = '50px';
  wrapper.value = 'width:375px;height:736px;margin:0 auto 0;position:relative';
} else {
  var deviceWidth = document.documentElement.clientWidth;
  if (deviceWidth > 750) deviceWidth = 750;
  document.documentElement.style.fontSize = deviceWidth / 7.5 + 'px';
  wrapper.value = 'width:100%;height:100%';
}

const saveData = (e) => {
  if(!e) {
    return
  } else {
    setToken(e.token);
    setUUid(e.di);
    setCid(e.cid);
    setVer(e.ver)
  }
};
onMounted(() => {
  saveData(getHashParams());
  console.log('当前版本为：', process.env.VUE_APP_VERSION_NUM);
});
</script>
<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #F5F5F9;
}
</style>
