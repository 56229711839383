import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/homePage.vue'),
    meta: { title: '积分商城' }
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import(/* webpackChunkName: "home" */ '../views/integraRule.vue'),
    meta: { title: '积分规则' }
  },
  {
    path: '/integralUseList',
    name: 'integralUseList',
    component: () => import(/* webpackChunkName: "home" */ '../views/integralUseList.vue'),
    meta: { title: '积分明细' }
  },
  {
    path: '/exchangeList',
    name: 'exchangeList',
    component: () => import(/* webpackChunkName: "home" */ '../views/exchangeList.vue'),
    meta: { title: '兑换记录' }
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail.vue'),
    meta: { title: '商品详情' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 如果路由元信息中定义了title，则动态更改页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router
